<template>
	<v-layout v-if="model" v-observe-visibility="visibilityChanged" justify-center align-center :style="displayThumbnailStyle">
		<v-layout class="thumbnail-container" align-center justify-center>
			<v-flex v-if="isEmptyDocument" class="thumbnail-content" shrink align-center justify-center>
				<v-tooltip top color="error">
					<template v-slot:activator="{ on: emptyTooltip }">
						<v-icon color="error" :size="`${displayIconSize}px`" v-on="emptyTooltip">error_outline</v-icon>
					</template>
					<span v-t="'documents.errors.is_empty'" />
				</v-tooltip>
			</v-flex>
			<v-flex v-else-if="hasThumbnail && imageFailedLoading" class="thumbnail-content" shrink align-center justify-center>
				<v-icon :color="nodeIcon.color" :size="`${displayIconSize}px`">{{ nodeIcon.icon }}</v-icon>
			</v-flex>
			<v-flex v-else-if="hasThumbnail && hasBeenVisible && !imageFailedLoading" class="thumbnail-content" shrink align-center justify-center>
				<v-img
					contain
					:style="displayThumbnailStyle"
					:src="thumbnail"
					importance="medium"
					class="justify-center align-center"
					@error="imageFailedLoading = true"
					@load="imageFinishedLoading()"
				>
					<template v-slot:placeholder>
						<v-layout row justify-center align-center>
							<v-icon :color="nodeIcon.color" :size="`${displayIconSize}px`">{{ nodeIcon.icon }}</v-icon>
						</v-layout>
					</template>
				</v-img>
			</v-flex>
			<v-flex v-else class="thumbnail-content" shrink align-center justify-center>
				<v-icon :color="nodeIcon.color" :size="`${displayIconSize}px`">{{ nodeIcon.icon }}</v-icon>
			</v-flex>
			<v-flex v-if="hasBeenVisible" class="badge-slot top left" :style="displayBadgeStyle">
				<slot name="topLeftBadge"></slot>
			</v-flex>
			<v-flex v-if="hasBeenVisible" class="badge-slot top right" :style="displayBadgeStyle">
				<slot :style="displayBadgeStyle" name="topRightBadge"></slot>
			</v-flex>
			<v-flex v-if="hasBeenVisible && hasThumbnail && !imageLoading" class="badge-slot bottom right" :style="displayBadgeStyle">
				<v-layout :style="displayBadgeStyle">
					<v-flex>
						<v-icon class="badge-file-icon" :color="nodeIcon.color">
							{{ nodeIcon.icon }}
						</v-icon>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex v-if="hasBeenVisible" class="badge-slot bottom left" :style="displayBadgeStyle">
				<slot :style="displayBadgeStyle" name="bottomLeftBadge"></slot>
			</v-flex>
			<v-flex v-if="hasBeenVisible" class="overlay" shrink align-center justify-center>
				<slot name="overlay"></slot>
			</v-flex>
		</v-layout>
	</v-layout>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
export default {
	name: 'NodeThumbnail',
	mixins: [DocumentVersionsModuleGuard],
	props: {
		small: {
			type: Boolean,
			required: false,
			default: false
		},
		medium: {
			type: Boolean,
			required: false,
			default: false
		},
		large: {
			type: Boolean,
			required: false,
			default: true
		},
		isBackToParentFolder: {
			type: Boolean,
			required: false,
			default: false
		},
		open: {
			type: Boolean,
			required: false,
			default: false
		},
		doNotShowSubfoldersAsOpened: {
			type: Boolean,
			required: false,
			default: false
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: [Object, Array],
			required: false,
			default: () => [],
			validator: function (value) {
				let result = true
				if (value && !value.hasOwnProperty('icon')) {
					result = "missing 'icon' property on item"
				} else if (value && !value.icon.hasOwnProperty('color')) {
					result = "missing 'color' property on item.icon"
				} else if (value && !value.icon.hasOwnProperty('icon')) {
					result = "missing 'icon' property on item.icon"
				}
				return result
			}
		}
	},
	data: function () {
		return {
			hasBeenVisible: false,
			imageLoading: true,
			imageFailedLoading: false,
			dataLoadingTimeout: null,
			loadImageTries: 0
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		nodes: function () {
			return Array.isArray(this.model) ? this.model : [this.model]
		},
		node: function () {
			return this.nodes.length == 1 ? this.nodes[0] : null
		},
		documents: function () {
			return this.nodes.length > 1 && this.nodes.every(node => node?.is_document) ? this.nodes : null
		},
		folders: function () {
			return this.nodes.length > 1 && this.nodes.every(node => node?.is_folder) ? this.nodes : null
		},
		mixedNodes: function () {
			return this.nodes.length > 1 && !this.folders && !this.documents ? this.nodes : null
		},
		document: function () {
			return this.node?.is_document ? this.node : null
		},
		folder: function () {
			return this.node?.is_folder ? this.node : null
		},
		backToParentFolder: function () {
			return this.folder && this.isBackToParentFolder ? this.folder : null
		},
		trashFolder: function () {
			return this.folder?.is_trash ? this.folder : null
		},
		displayIconSize: function () {
			let result
			if (this.small) {
				result = '24'
			} else if (this.medium) {
				result = '50'
			} else if (this.large) {
				result = '160'
			}
			return result
		},
		displayThumbnailSize: function () {
			let result
			if (this.small) {
				result = '24'
			} else if (this.medium) {
				result = '70'
			} else if (this.large) {
				result = '160'
			}
			return result
		},
		displayThumbnailStyle: function () {
			return `
				width: ${this.displayThumbnailSize}px;
				height: ${this.displayThumbnailSize}px;
				min-width: ${this.displayThumbnailSize}px;
				min-height: ${this.displayThumbnailSize}px;
				max-width: ${this.displayThumbnailSize}px;
				max-height: ${this.displayThumbnailSize}px;
			`
		},
		displayBadgeSize: function () {
			let result
			if (this.small) {
				result = '18'
			} else if (this.medium) {
				result = '20'
			} else if (this.large) {
				result = '28'
			}
			return result
		},
		displayBadgeStyle: function () {
			return `
				width: ${this.displayBadgeSize}px;
				height: ${this.displayBadgeSize}px;
				min-width: ${this.displayBadgeSize}px;
				min-height: ${this.displayBadgeSize}px;
				max-width: ${this.displayBadgeSize}px;
				max-height: ${this.displayBadgeSize}px;
			`
		},
		nodeIcon: function () {
			const result = {
				color: this.nodes.some(node => node.is_folder && node.is_default) ? 'primary darken-2' : 'primary'
			}
			if (this.folders) {
				result.icon = this.folders.every(folder => !folder.has_child) ? 'far fa-folder' : 'fas fa-folder'
			} else if (this.documents) {
				result.icon = 'fas fa-file'
			} else if (this.mixedNodes) {
				result.icon = 'perm_media'
			} else if (this.folder) {
				Object.assign(result, this.folderIcon)
			} else if (this.document) {
				result.color = this.document?.icon?.color
				result.icon = this.document?.icon?.icon
			}
			return result
		},
		folderIcon: function () {
			const result = {}
			if (this.backToParentFolder) {
				result.icon = 'subdirectory_arrow_left'
			} else if (this.trashFolder) {
				result.color = 'secondary darken-2'
				result.icon = this.trashFolder.is_empty ? 'delete_outline' : 'delete'
			} else if (this.folder) {
				result.icon = this.folder.is_empty ? 'far' : 'fas'
				result.icon += ' '
				result.icon += (this.folder.opened && !this.doNotShowSubfoldersAsOpened) || this.open ? 'fa-folder-open' : 'fa-folder'
			}
			return result
		},
		isEmptyDocument: function () {
			return this.document && this.version && parseInt(this.version.size) == 0
		},
		thumbnail: function () {
			let result = null
			let thumbnailBaseUrl = null
			if (this.version?.thumbnail) {
				thumbnailBaseUrl = new URL(this.version.thumbnail)
			} else if (this.document?.latestVersion?.thumbnail) {
				thumbnailBaseUrl = new URL(this.document.latestVersion.thumbnail)
			} else if (this.document?.thumbnail) {
				thumbnailBaseUrl = new URL(this.document.thumbnail)
			} else if (this.document?.tiny_thumbnail) {
				thumbnailBaseUrl = new URL(this.document.tiny_thumbnail)
			}
			if (thumbnailBaseUrl) {
				let thumbnailMode
				if (this.small) {
					thumbnailMode = 'extra-small'
				} else if (this.medium) {
					thumbnailMode = 'small'
				} else if (this.large) {
					thumbnailMode = 'preview'
				} else {
					thumbnailMode = 'preview'
				}
				thumbnailBaseUrl.searchParams.append('mode', thumbnailMode)
				result = thumbnailBaseUrl.toString()
			}
			return result
		},
		hasThumbnail: function () {
			return this.document != null && this.document.has_thumbnail && this.thumbnail != null
		}
	},
	watch: {
		thumbnail: {
			handler: function (newValue, oldValue) {
				if (newValue != oldValue) {
					this.imageLoading = true
					this.imageFailedLoading = false
				}
			}
		},
		imageFailedLoading: {
			handler: function (value) {
				if (value) {
					this.startPollingDataLoad()
				}
			}
		}
	},
	destroyed: function () {
		this.stopPollingDataLoad()
	},
	methods: {
		loadDetails: function (force = false) {
			if (this.document && this.document.has_thumbnail && (!this.document.thumbnail || force)) {
				const currentVendorId = this.document.vendor_id ? this.document.vendor_id : this.vendorId
				if (this.version) {
					this.service.getDocumentVersion(currentVendorId, this.document, this.version)
				} else {
					this.service.getDocumentVersions(currentVendorId, this.document, { filters: { latest: true } })
				}
			}
		},
		visibilityChanged: async function (isVisible) {
			if (!this.hasBeenVisible && isVisible) {
				await this.loadDetails()
				this.hasBeenVisible = isVisible
			}
		},
		imageFinishedLoading: function () {
			this.imageLoading = false
		},
		startPollingDataLoad: function () {
			this.loadImageTries = this.loadImageTries + 1
			this.stopPollingDataLoad()
			if (this.imageFailedLoading && this.loadImageTries <= 1) {
				this.dataLoadingTimeout = setTimeout(() => {
					this.imageFailedLoading = false
				}, 3000)
			}
		},
		stopPollingDataLoad: function () {
			if (this.dataLoadingTimeout) {
				clearTimeout(this.dataLoadingTimeout)
			}
		}
	}
}
</script>

<style scoped>
.thumbnail-container {
	position: relative;
}

.overlay {
	position: absolute;
	max-height: 100%;
	max-width: 100%;
	align-items: center;
	justify-content: center;
}

.overlay > * {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.badge-slot {
	position: absolute;
	height: 20%;
	width: 20%;
	max-width: 20%;
	max-height: 20%;
}

.badge-slot > * {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.top {
	top: 0;
	transform: translate(-50%, -50%);
}

.left {
	left: 0;
	transform: translate(-50%);
}

.right {
	right: 0;
	transform: translate(50%);
}

.bottom {
	bottom: 0;
	transform: translate(50%);
}
.badge-file-icon {
	font-size: 100%;
}
</style>
